<template>
  <v-expand
    v-for="({ question, answer }, index) in items"
    :key="index"
    ref="faqs"
    class="relative first:border-t border-b border-[rgba(255,255,255,0.18)] overflow-hidden"
    @on-expand="onExpand(index)"
  >
    <template #trigger="{ expand }">
      <button
        class="flex w-full items-center justify-between text-xs font-medium cursor-pointer px-[12px] text-left"
        type="button"
      >
        <span
          class="mr-6 text-[18px] leading-[30px] py-[18px] font-[520] -tracking-[0.005em]"
          >{{ question }}</span
        >
        <span
          class="relative transform origin-center flex-shrink-0 transition-transform duration-300"
          :class="expand ? 'rotate-45' : 'rotate-0'"
        >
          <svg
            class="transition-transform"
            :class="expand ? '-rotate-45' : 'rotate-0'"
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H9.5C9.77614 0.5 10 0.723858 10 1C10 1.27614 9.77614 1.5 9.5 1.5H1C0.723858 1.5 0.5 1.27614 0.5 1Z"
              fill="#FEFEFE"
            />
          </svg>
          <svg
            class="absolute inset-0 transition-transform"
            :class="expand ? '-rotate-45' : '-rotate-90'"
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H9.5C9.77614 0.5 10 0.723858 10 1C10 1.27614 9.77614 1.5 9.5 1.5H1C0.723858 1.5 0.5 1.27614 0.5 1Z"
              fill="#FEFEFE"
            />
          </svg>
        </span>
      </button>
    </template>
    <template #default>
      <p
        class="font-[520] px-[12px] pb-[24px] text-[#8F8F8F] text-[16px] leading-[22px] tracking-[-0.48px] max-w-[605px] whitespace-pre-line"
      >
        {{ answer }}
      </p>
    </template>
  </v-expand>
</template>

<script setup lang="ts">
import { ref } from "vue";
import OneSphere from "./OneSphere.vue";
import VExpand from "./VExpand.vue";

const faqs = ref([]);

defineProps<{
  items: { question: string; answer: string }[];
}>();

function onExpand(index: number) {
  faqs.value.forEach((item, currentIndex) => {
    if (index != currentIndex) {
      // @ts-expect-error Untyped
      item.close();
    }
  });
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
